import React, { useEffect } from 'react'
import { graphql, Link, PageProps } from 'gatsby'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { JobPosting, WithContext } from 'schema-dts'
import { SEO } from '@/components/SEO'
import { CenteredResponsiveContainer, Layout } from './Layout'
import { officeLocationMap, officeCountryMap } from '@/utils/officeMap'

const loadScript = (src: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.onload = () => resolve()
    return (document.body && document.body.appendChild(script)) || reject()
  })

let iframe: null | UntypedObject

const loadApplicationForm = async (greenhouseId: string) => {
  if (!(window as any).Grnhse) {
    await loadScript(
      `https://boards.greenhouse.io/embed/job_board/js?for=jumptrading&token=${greenhouseId}`,
    )
  }
  iframe = (window as any).Grnhse?.Iframe.load(greenhouseId)
}

const unloadApplicationForm = () => {
  if (iframe) iframe.htmlElement = null
}

const decodeContent = (content: string): string =>
  content
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&')
type JobPageProps = {
  data: { greenhouseJob: GreenhouseJobProps }
} & PageProps<{}, {}, { prevPath: string }>

const JobPage = ({ data, location: windowLocation }: JobPageProps) => {
  if (!data) return null
  const { title, content, gh_Id, location, offices } = data?.greenhouseJob
  // console.log('data.greenhouseJob', data?.greenhouseJob)

  useEffect(() => {
    loadApplicationForm(gh_Id)
    return unloadApplicationForm
  }, [])
  const decoded = decodeContent(content)
  // console.log('decoded job content: ', decoded)

  let descParts = decoded.split('>')
  let desc = ''
  if (descParts[1]) {
    desc = descParts[1].split('<')[0] || ''
    // take the first 155 chars (max meta description length)
    // desc = desc.slice(0, 154)
  }

  const explicitlyAllowsRemote = location.name.toLowerCase().includes('remote')
  const internship = title.toLowerCase().includes('intern')

  const fullTime = !['campus', 'intern'].some((t) => title.toLowerCase().includes(t))

  const jsonLd: WithContext<JobPosting> = {
    name: title,
    '@context': 'https://schema.org',
    '@type': 'JobPosting',
    title: title,
    description: decoded,
    identifier: {
      '@type': 'PropertyValue',
      name: 'greenhouseId',
      value: gh_Id,
    },
    employmentType: ['FULL_TIME', 'CONTRACTOR'],
    hiringOrganization: {
      '@type': 'Organization',
      '@id': 'https://www.jumptrading.com/#organization',
      name: 'Jump Trading',
      sameAs: 'https://www.jumptrading.com',
      logo: 'https://www.jumptrading.com/logo-and-name.png',
    },
    directApply: 'True',
  }

  // loop through offices and add each to jsonLd
  offices.map((office) => {
    const jobLoc = jsonLd.jobLocation
    if (officeLocationMap[office.id]) {
      jsonLd.jobLocation = [officeLocationMap[office.id], ...((jobLoc as any) || [])]
    }

    const locReq = jsonLd.applicantLocationRequirements
    if (officeCountryMap[office.id]) {
      jsonLd.applicantLocationRequirements = [
        officeCountryMap[office.id],
        ...((locReq as any) || []),
      ]
    }
  })

  if (explicitlyAllowsRemote) {
    jsonLd.jobLocationType = 'TELECOMMUTE'
  }

  if (!internship) {
    jsonLd.employmentType = 'FULL_TIME'
    jsonLd.experienceRequirements = {
      '@type': 'OccupationalExperienceRequirements',
      monthsOfExperience: 12,
    }
    jsonLd.experienceInPlaceOfEducation = 'True'
    jsonLd.educationRequirements = {
      '@type': 'EducationalOccupationalCredential',
      credentialCategory: 'bachelor degree',
    }
  } else {
    jsonLd.employmentType = 'INTERN'
    jsonLd.educationRequirements = 'no requirements'
    jsonLd.experienceRequirements = 'no requirements'
  }

  // console.log('jsonLd', JSON.stringify(jsonLd, undefined, 2))
  return (
    <Layout>
      <SEO title={title} description={desc ? desc : title}>
        <script type="application/ld+json">{JSON.stringify(jsonLd, undefined, 4)}</script>
      </SEO>
      <CenteredResponsiveContainer className="pl-0 pr-0 md:px-0 mx-auto">
        <div className="flex mx-auto justify-center gap-3 px-0 md:px-0">
          <aside className="hidden lg:block  sticky top-48 mt-10 h-fit">
            <div className="w-80">
              <ul className="">
                <li className="pb-5">
                  <Link
                    to={windowLocation.state?.prevPath || '/careers/'}
                    className="text-jump-red font-medium text-lg flex items-center"
                  >
                    <ChevronLeftIcon className="h-4 w-4 mr-2" />
                    <div>All roles</div>
                  </Link>
                </li>
                <li className="border-b-2 border-b-medium-gray-1 py-4">
                  <h1 className="text-3xl font-bold">{title}</h1>
                </li>
                <li className="border-b-2 border-b-medium-gray-1 py-4">{location.name}</li>

                {fullTime && <li className="border-b-2 border-b-medium-gray-1 py-4">Full time</li>}
                {internship && (
                  <li className="border-b-2 border-b-medium-gray-1 py-4">Internship</li>
                )}
              </ul>
            </div>
          </aside>

          <div id="grnhse_app" className="max-w-2xl w-full" />
        </div>
      </CenteredResponsiveContainer>
    </Layout>
  )
}

export default JobPage

export const jobPageQuery = graphql`
  query ($id: String!) {
    greenhouseJob(id: { eq: $id }) {
      ...GreenhouseJobFragment
    }
  }
`
