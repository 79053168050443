export const officeLocationMap = {
  Greenhouse__Office__58179: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Amsterdam',
      addressRegion: 'Noord-Holland',
      addressCountry: 'Netherlands',
    },
  },
  Greenhouse__Office__79248: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Austin',
      addressRegion: 'TX',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__73334: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Sydney',
      addressRegion: 'Australian Capital Territory',
      addressCountry: 'Australia',
    },
  },
  Greenhouse__Office__74001: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'São Paulo',
      addressCountry: 'Brazil',
    },
  },
  Greenhouse__Office__59653: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Bristol',
      addressRegion: 'England',
      addressCountry: 'United Kingdom',
    },
  },
  Greenhouse__Office__2450: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Champaign',
      addressRegion: 'IL',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__2446: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Chicago',
      addressRegion: 'IL',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__2554: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Clifton',
      addressRegion: 'NJ',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__5402: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Cranberry Township',
      addressRegion: 'PA',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__3842: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Dallas',
      addressRegion: 'TX',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__2553: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Fort Wayne',
      addressRegion: 'IN',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__74800: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressRegion: 'Hong Kong',
      addressCountry: 'CN',
    },
  },
  Greenhouse__Office__63485: {
    '@type': 'Country',
    name: 'India',
  },
  Greenhouse__Office__79898: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: '',
      addressRegion: 'Tel Aviv District',
      addressCountry: 'Israel',
    },
  },
  Greenhouse__Office__69255: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Tokyo',
      addressCountry: 'Japan',
    },
  },
  Greenhouse__Office__2448: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'London',
      addressCountry: 'United Kingdom',
    },
  },
  Greenhouse__Office__2447: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'New York',
      addressRegion: 'NY',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__62272: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Maastricht',
      addressRegion: 'Limburg',
      addressCountry: 'Netherlands',
    },
  },
  Greenhouse__Office__74271: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Paris',
      addressRegion: 'Paris',
      addressCountry: 'France',
    },
  },
  Greenhouse__Office__2556: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Pittsburgh',
      addressRegion: 'PA',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__59202: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Portage',
      addressRegion: 'IN',
      addressCountry: 'US',
    },
  },
  Greenhouse__Office__19559: {
    '@type': 'Country',
    name: 'China',
  },
  Greenhouse__Office__2449: {
    '@type': 'Country',
    name: 'Singapore',
  },
  Greenhouse__Office__14432: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Slough',
      addressCountry: 'United Kingdom',
    },
  },
  Greenhouse__Office__80269: {
    '@type': 'Place',
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Seoul',
      addressRegion: 'Seoul',
      addressCountry: 'South Korea',
    },
  },
}

export const officeCountryMap = {
  Greenhouse__Office__58179: {
    '@type': 'Country',
    name: 'Netherlands',
  },
  Greenhouse__Office__79248: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__73334: {
    '@type': 'Country',
    name: 'Australia',
  },
  Greenhouse__Office__74001: {
    '@type': 'Country',
    name: 'Brazil',
  },
  Greenhouse__Office__59653: {
    '@type': 'Country',
    name: 'United Kingdom',
  },
  Greenhouse__Office__2450: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__2446: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__2554: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__5402: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__3842: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__2553: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__74800: {
    '@type': 'Country',
    name: 'China',
  },
  Greenhouse__Office__63485: {
    '@type': 'Country',
    name: 'India',
  },
  Greenhouse__Office__79898: {
    '@type': 'Country',
    name: 'Israel',
  },
  Greenhouse__Office__69255: {
    '@type': 'Country',
    name: 'Japan',
  },
  Greenhouse__Office__2448: {
    '@type': 'Country',
    name: 'United Kingdom',
  },
  Greenhouse__Office__2447: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__62272: {
    '@type': 'Country',
    name: 'Netherlands',
  },
  Greenhouse__Office__74271: {
    '@type': 'Country',
    name: 'France',
  },
  Greenhouse__Office__2556: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__59202: {
    '@type': 'Country',
    name: 'US',
  },
  Greenhouse__Office__19559: {
    '@type': 'Country',
    name: 'China',
  },
  Greenhouse__Office__2449: {
    '@type': 'Country',
    name: 'Singapore',
  },
  Greenhouse__Office__14432: {
    '@type': 'Country',
    name: 'United Kingdom',
  },
  Greenhouse__Office__80269: {
    '@type': 'Country',
    name: 'South Korea',
  },
}
